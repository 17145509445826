"use client";
import { useEffect } from "react";
import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import SecondNavbar from "./components/Navbar/SecondNavbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logPageView } from './analytics';



const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const Favorite = lazy(() => import("./pages/Favorite"));
const Reduction = lazy(() => import("./pages/Reduction"));


const Product = lazy(() => import("./pages/Product"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Contact = lazy(() => import("./pages/Contact"));
const Profil = lazy(() => import("./pages/Profil"));
const Store = lazy(() => import("./pages/Store"));
const ClientCart = lazy(() => import("./pages/ClientCart"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const NotFound = lazy(() => import("./pages/NotFound"));

const knownRoutes = [
  "/",
  "/shop",
  "/product/:id",
  "/cart",
  "/login",
  "/register",
  "/contact",
  "/profil",
  "/store",
  "/clientcart/:id",
  "/reset-password/:token",
  "/favorite",
  "/accueil",
  "/reduction",
];

function NavbarWrapper() {
  const location = useLocation();

    useEffect(() => {
        logPageView();
    }, [location]);

  const isKnownRoute = knownRoutes.some((route) => {
    const regex = new RegExp(`^${route.replace(/:[^\s/]+/g, "([^/]+)")}$`);
    return regex.test(location.pathname);
  });

  if (!isKnownRoute || location.pathname === "/accueil" || location.pathname === "/contact") {
    return <SecondNavbar />;
  }

  return <NavBar />;
}

function App() {
  return (
      <Suspense fallback={<Loader />}>
        <Router>
          <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />

          <NavbarWrapper />

          <Routes>
            <Route path="/accueil" element={<Home />} />
            <Route path="/" element={<Shop />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/store" element={<Store />} />
            <Route path="/clientcart/:id" element={<ClientCart />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/reduction" element={<Reduction />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />
        </Router>
      </Suspense>
  );
}

export default App;
