import React, { useEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../app/features/cart/cartSlice";
import "./navbar.css";
import { HiQueueList } from "react-icons/hi2";

import logoImage from "../../assets/logos/logo_xl.png";
import { CgProfile } from "react-icons/cg";
import { CiLogout } from "react-icons/ci";
import { TbLogout2 } from "react-icons/tb";
import { PiPercentFill } from "react-icons/pi";

import axios from "axios";

const NavBar = () => {
  const { cartList } = useSelector((state) => state.cart);
  const { favoriteList } = useSelector((state) => state.favorite);
  const [expand, setExpand] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [role, setRole] = useState(localStorage.getItem("role"));
  const dispatch = useDispatch();

  const backendApiUrl = process.env.REACT_APP_API_URL;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem("token"));
    };

    // Listen for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial check for user
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      checkLoggedInAndLogoutIfNecessary();
      const userInfo = JSON.parse(loggedInUser);
      setUser(userInfo);
      setRole(localStorage.getItem("role"));
    }

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
    window.location.href = "/login";
    dispatch(clearCart());
    setUser({});
  };

  const [reloaded, setReloaded] = useState("false");
  const [isAvatarDropdownOpen, setIsAvatarDropdownOpen] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setReloaded(localStorage.getItem("reload"));
    }, 1000);
  }, [token, reloaded]);

  const toggleAvatarDropdown = () => {
    setIsAvatarDropdownOpen(!isAvatarDropdownOpen);
  };

  const getUserInitial = () => {
    return user && user.lastname
      ? user.lastname.charAt(0) + "." + user.firstname.charAt(0)
      : "";
  };

  // Ref for the dropdown
  const dropdownRef = useRef(null);

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAvatarDropdownOpen(false);
      }
    };

    if (isAvatarDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAvatarDropdownOpen]);

  function checkLoggedInAndLogoutIfNecessary() {
    let url = backendApiUrl + "/auth/checkLoggedIn";

    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.loggedIn === false) {
          //handleLogout();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div style={{ position: "relative" }}>
      <div className="banner-outer">
        <div className="banner-inner responsive-wrapper">
          <p>
            💥 Profitez de Dr Deal gratuitement pendant son développement et
            faites des économies dès maintenant ! 💸
          </p>
        </div>
      </div>
      <div className="w-full px-4 text-gray-700 bg-white  newNavbar">
        <div className="container flex flex-col flex-wrap items-baseline justify-between py-1 px-0 md:flex-row max-w-7xl">
          <div className="flex items-center justify-between w-full md:w-auto navbarMenu">
            <div className="navbarLogo">
              <a
                href="/"
                className="textLogo text-xl font-black leading-none text-gray-900 select-none navbarBtnLink navbarLogo"
              >
                <img src={logoImage} alt="logo" className="NavBarLogoSmall" />
              </a>
              <button
                onClick={toggleMenu}
                className="navbarToggleBtn inline-flex items-center justify-center p-2 text-gray-700 rounded-md md:hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={
                      !isOpen
                        ? "M4 6h16M4 12h16m-7 6h7"
                        : "M6 18L18 6M6 6l12 12"
                    }
                  />
                </svg>
              </button>
            </div>

            <div
              className={`${
                isOpen ? "block" : "hidden"
              } md:flex md:flex-row md:items-center w-full md:w-auto mt-4 md:mt-0 md:items-baseline newNavbBarLinks`}
            >
              <nav className="flex flex-col md:flex-row md:border-gray-200 newNavbBarLinksNav">
                {role === "ROLE_ADMIN" && (
                  <a
                    href="/store"
                    className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900 "
                  >
                    Magasin
                  </a>
                )}

                {role === "ROLE_CLIENT" && (
                  <>
                  <Nav.Link
                      as={Link}
                      to="/reduction"
                      onClick={() => setExpand(false)}
                      className={"d-flex align-items-center cart-link "}
                    >
                      <span>
                        Réduction
                      </span>
                      <span className="relative inline-flex items-center sm:p-2 lg:p-0 text-sm font-small text-center text-white bg-white-700 rounded-lg hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800">
                        <PiPercentFill className={"favoriteListIcon"} />
                      </span>
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/favorite"
                      onClick={() => setExpand(false)}
                      className={"d-flex align-items-center cart-link ml-5 "}
                    >
                      <span>Ma liste d'achat</span>
                      <span className="relative inline-flex items-center sm:p-2 lg:p-0 text-sm font-small text-center text-white bg-white-700 rounded-lg hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800">
                        <HiQueueList className={"favoriteListIcon"} />
                      </span>
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/cart"
                      onClick={() => setExpand(false)}
                      className={"d-flex align-items-center cart-link ml-5"}
                    >
                      <span>Panier</span>
                      <span className="relative inline-flex items-center sm:p-2 lg:p-0 text-sm font-small text-center text-white bg-white-700 rounded-lg hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800">
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="cartListIcon absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold  border-2  rounded-full -top-2 -end-2 ">
                          {cartList.length}
                        </div>
                      </span>
                    </Nav.Link>
                  </>
                )}
              </nav>

              {!token && (
                <div className="flex flex-col  space-x-0 justify-between md:flex-row md:space-x-6 md:mt-0 navbarBtnLinks">
                  <a
                    href="/login"
                    className="navbarBtnLink inline-flex items-center justify-center px-4 py-2 mt-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-sky-500 border border-transparent rounded-md shadow-sm md:mt-0 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    Se connecter
                  </a>
                  <a
                    href="/register"
                    className="navbarBtnLink inline-flex items-center justify-center px-4 py-2 mt-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-sky-500 border border-transparent rounded-md shadow-sm md:mt-0 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    S'inscrire
                  </a>
                </div>
              )}

              <div className="flex items-center md:space-x-6 logoutDropdown">
                {/* Avatar Dropdown */}
                {token && user && (
                  <div className="relative" ref={dropdownRef}>
                    <button
                      onClick={toggleAvatarDropdown}
                      className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-800 font-bold text-sm"
                    >
                      {getUserInitial()}
                    </button>

                    {isAvatarDropdownOpen && (
                      <div className="navbarDropDown w-60 bg-white border rounded shadow-lg py-2">
                        {user && (
                          <small className="d-flex align-items-center block px-4 py-2 text-gray-800">
                            <span className="text-gray-800 dark:text-white">
                              {user.lastname} {user.firstname}
                            </span>
                          </small>
                        )}
                        {user.email && (
                          <>
                            <small className="d-flex align-items-center block px-4 py-2 text-gray-800">
                              <span className="text-gray-800 dark:text-white">
                                {user.email}
                              </span>
                            </small>
                            <hr />
                          </>
                        )}
                        <button
                          onClick={() => (window.location.href = "/profil")}
                          className=""
                        >
                          Profil <CgProfile />
                        </button>
                        <button onClick={openModal} className="  ">
                          Se déconnecter <TbLogout2 />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="p-4 md:p-5 text-center flex flex-column">
                  <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                    Se déconnecter
                  </h2>
                  <p className="text-gray-600 dark:text-gray-300">
                    Êtes-vous sûr de vouloir vous déconnecter ?
                  </p>
                  <div className="flex justify-center mt-4 gap-2">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      onClick={handleLogout}
                    >
                      Se déconnecter
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                      onClick={closeModal}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
